<template>
	<div v-if="!loading && blocksLoaded" class="space-blocks content-padding">
        <div class="blocks">
            <BlockList
                :block-list="blocksList"
                :on-dragging="onDragging"
                :drag-end="dragEnd"
                :categories="categories"
            />
        </div>
        <div class="editor">
            <div
                id="drop-area__0"
                @drop="drop"
                @dragover="allowDrop"
                @dragleave="dragLeave"
                class="add-area"
            >
                <div class="plus">
                    <svg data-v-09c2af80="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="right-arrow svg-inline--fa fa-plus fa-w-14"><path data-v-09c2af80="" fill="white" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" class=""></path></svg>
                </div>
                
            </div>
            <div class="block" v-for="(block, key) in localBody.structure" :key="`block-${key}`">
                <BaseCard :padding="'25px'">
                    <div v-for="(obj, fieldName) in blocksList[block.component].fields" :key="`block-${key}-field-${fieldName}`" class="field">
                        <!-- v-if="!obj.location || obj.location == 'block'" -->
                        <CollapsibleContentLight
                            :wrap="typeof obj.optional !== 'undefined' && obj.optional === true"
                            :title="obj.label || fieldName"
                            :content-padding="'15px 0px 15px 0px'"
                            :button-bg-color="'#f6f6f6'"
                            :button-padding="'7px 15px'"
                            :collapse-id="`block-${key}-field-${fieldName}`"
                            :margin-bottom="'15px'"
                        >
                            <div
                                class="item"
                                v-if="['text', 'number', 'hidden', 'password', 'email'].includes(obj.datatype)"
                            >
                                <FormulateInput
                                    :type="obj.datatype"
                                    :name="fieldName"
                                    :value="obj.default || ''"
                                    :label="obj.label"
                                    @keyup="updateFieldData($event.target.value, fieldName, block.dataId)"
                                />
                            </div>
                            <div
                                class="item"
                                v-else-if="obj.datatype == 'textarea'"
                            >
                                <FormulateInput
                                    type="textarea"
                                    :name="fieldName"
                                    error-behavior="blur"
                                    :label="obj.label"
                                    :value="obj.default || ''"
                                    @input="updateFieldData($event, fieldName, block.dataId)"
                                />
                            </div>
                            <div
                                class="item"
                                v-else-if="obj.datatype == 'checkbox'"
                            >
                                <FormulateInput
                                    :type="obj.datatype"
                                    :name="fieldName"
                                    :value="obj.default || ''"
                                    :label="obj.label"
                                    @change="updateFieldData($event.target.checked, fieldName, block.dataId)"
                                />
                            </div>
                            <div
                                class="item"
                                v-else-if="obj.datatype == 'image'"
                            >
                                <FormulateInput
                                    type="image"
                                    :name="fieldName"
                                    error-behavior="blur"
                                    :label="obj.label"
                                    :value="obj.default || ''"
                                    @input="fileUpdated($event, fieldName, block.dataId)"
                                />
                                <img class="image-preview" v-if="obj.default && typeof obj.default == 'string'" :src="obj.default" />
                            </div>
                            <div v-else-if="obj.datatype == 'richtext'" class="item">
                                <TextEditorCard
                                    :extra-configs="{
                                        hideBgColorPicker: true,
                                        hideColorPicker: true,
                                    }"
                                    :title="obj.label"
                                    :value="obj.default || ''"
                                    @changed="updateFieldData($event.value, fieldName, block.dataId)"
                                />
                            </div>
                            <div
                                class="item"
                                v-else-if="obj.datatype == 'select'"
                            >
                                <label>
                                    {{ obj.label }}
                                </label>
                                <select @change="updateFieldData($event.target.value, fieldName, block.dataId)">
                                    <option v-for="(option, index) in obj.options" :value="option.value" :key="`option-${index}-${option.value}`" :selected="option.selected == true ? true : false">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="item"
                                v-else-if="obj.datatype == 'list'"
                            >
                                <SimpleList
                                    :label="obj.label"
                                    :current-value="obj.default || []"
                                    :structure="createStructure(obj.object)"
                                    @list-updated="updateFieldData($event, fieldName, block.dataId)"
                                />
                            </div>
                        </CollapsibleContentLight>
                    </div>
                </BaseCard>
                <div
                    :id="`drop-area__${key + 1}`"
                    @drop="drop"
                    @dragover="allowDrop"
                    @dragleave="dragLeave"
                    class="add-area"
                >
                    <div class="plus">
                        <svg data-v-09c2af80="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="right-arrow svg-inline--fa fa-plus fa-w-14"><path data-v-09c2af80="" fill="white" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" class=""></path></svg>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="block-options">
            <BaseCard :padding="'25px'">

            </BaseCard>
        </div>
	</div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { debounce } from "@/services/debounce";

    export default {
        name: 'SpaceBlocks',
        components: {
            BaseCard: () => import("@/components/Cards/BaseCard"),
            BlockList: () => import("@/components/Blocks/BlockList"),
            CollapsibleContentLight: () => import("@/components/Collapsible/CollapsibleContentLight"),
            SimpleList: () => import("@/components/List/SimpleList.vue"),
            TextEditorCard: () => import("@/components/Input/TextEditorCard")
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            localData: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            spaceFields: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                blocksList: {},
                categories: {},
                localBody: {
                    structure: [],
                    data: {}
                },
                blocksLoaded: false,
                activeBlock: {}
            }
        },
        computed: {
            ...mapGetters('helper', ['labels'])
        },
        methods: {
            ...mapActions('notification', ['addNotification']),
            ...mapActions('blockEditor', ['listBlocks']),
            createStructure(blockListFields) {
                let result = {};

                Object.keys(blockListFields).forEach(fieldName => {
                    result[fieldName] = {
                        label: blockListFields[fieldName].label,
                        kind: blockListFields[fieldName].datatype
                    };
                });

                return result;
            },
            updateFieldData(val, field, dataId) {
                console.log('Updating, dataId:', dataId);
                console.log('Updating, field:', field);
                console.log('Updating, val is:', val);
                debounce(() => {
                    this.$set(this.localBody.data[dataId], field, val);
                    console.log('New val is:', this.localBody.data[dataId][field]);
                    console.log('Local body is:', this.localBody);
                    this.$emit('data-updated', 'body', this.localBody);
                }, 500, `${dataId}-${field}`);
            },
            fileUpdated(value, field, dataId) {
                if (value.files?.[0]?.file) this.updateFieldData(value.files[0].file, field, dataId);
            },
            allowDrop(ev) {
                ev.currentTarget.style.background = "lightblue";
                ev.preventDefault();
            },
            dragLeave(ev) {
                ev.currentTarget.style.background = "#f6f6f6";
            },
            drop(ev) {
                let parent = null;
                ev.preventDefault();
                ev.currentTarget.style.background = "#f6f6f6";
                let data = ev.dataTransfer.getData("text");
                console.log('First data:', data);
                let blockId = ev.dataTransfer.getData("blockId");
                data = data.split("__");

                let dropArea = ev.target.id.split("__");

                // get parent
                let possibleParent = ev.target.id.split("-drop");

                if (possibleParent[0] && possibleParent[0] !== ev.target.id) {
                    parent = possibleParent[0];
                }

                if (!blockId) {
                    blockId = undefined;
                }

                console.log('Data:', data);
                console.log('Drop area:', dropArea);
                console.log('Parent:', parent);
                console.log('Block id:', blockId);
                this.manipulateBlockFlow(data, dropArea, blockId, parent);
            },
            onDragging(ev) {
                let transferId = ev.target.getAttribute("transfer-id");

                if (transferId) {
                    ev.dataTransfer.setData("text", transferId);
                }
                if (ev.target.getAttribute("blockid")) {
                    ev.dataTransfer.setData("blockId", ev.target.getAttribute("blockid"));
                }
            },
            dragEnd() {
                console.log('Drag end');
            },
            manipulateBlockFlow(data, dropArea, id, parent) {
                // make new block if id is not provided
                if (typeof id === "undefined") id = this.makeId();

                if (parent) {
                    id = parent + "_" + id;
                }

                let blockObject = this.blocksList[data[0]];
                
                if (parseInt(data[1]) + 1 !== parseInt(dropArea[1])) {
                    this.updateStructure({
                        data,
                        dropArea,
                        id,
                        block: blockObject,
                        parent
                    });
                }
            },
            updateStructure(payload) {
                console.log('Update structure got:', payload);
                if (payload.absolute) {
                    if (Array.isArray(payload.data)) payload.data = Object.assign( {}, payload.data);

                    // absolutely updates the structure if there is no parent provided in current payload
                    if (!payload.parent || payload.parent === '' || typeof payload.parent !== 'string') {
                        this.localBody.structure = payload.data;
                    } else {
                        // make sure that structure is an array and loop through the items in structure to find parent element by dataId and then just replace children array with the one from payload.data
                        this.localBody.structure = Object.values(this.localBody.structure);

                        for (let i = 0; i < this.localBody.structure.length; i ++) {
                            this.updateStructureChild(this.localBody.structure[i], payload.parent, payload);
                        }
                    }

                    return;
                }

                let kind = 'block';

                // only valid for 'template' object that supports slots block
                if( payload.block && payload.block.is_slot ){
                    kind = 'slot';
                }

                // set block kind from block config, otherwise kind would be 'block'
                if( payload.block && payload.block.kind ){
                    kind = payload.block.kind;
                }

                let newBlockObject = { component: payload.data[0], dataId: payload.id, kind: kind };

                // in case current added block is slot we set slotId to be the same as dataId
                if( kind === 'slot' ){
                    newBlockObject.slotId = payload.id;
                }

                let parent = payload.parent ? payload.parent : null;

                // grab current structure, either form current slot or global if current slot is not set(reminder: currentSlot is only valid for page object (non-template))
                let structure = this.localBody.structure;

                if( !structure ) structure = [];

                if (!Array.isArray(structure)) {
                    structure = Object.values(structure);
                }

                // if parent is set then we are nesting blocks
                if(!parent) {
                    // add block to array / structure
                    if (payload.data[1]) {
                        structure.splice(payload.data[1], 1);
                        if (parseInt( payload.data[1] ) < parseInt(payload.dropArea[1])) {
                            structure.splice(parseInt( payload.dropArea[1] ) - 1, 0, newBlockObject);
                        } else {
                            structure.splice(payload.dropArea[1], 0, newBlockObject);
                        }
                    } else {
                        structure.splice(payload.dropArea[1], 0, newBlockObject);
                    }
                } else{
                    structure.forEach( (structureEl) => {
                        this.updateStructureChild(structureEl, parent, payload, newBlockObject)
                    } );
                }

                // replace the structure, either global one or under slot( if we are under page )
                this.localBody.structure = Object.assign({}, structure);

                // create empty data object for added block
                if (!this.localBody.data) this.localBody.data = {};
                
                // if kind is not slot(i.e it is block then we create empty object for storing its values in data object)
                if (kind !== 'slot' && !this.localBody.data[payload.id]) {
                    this.$set(this.localBody.data, payload.id, {});
                }
            },
            makeId() {
                let str = "id_";
                return (
                    str +
                    "xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
                    let r = (Math.random() * 16) | 0,
                        v = c == "x" ? r : (r & 0x3) | 0x8;
                    return v.toString(16);
                    })
                );
            },
            /* Recursively adds child from the payload to children array of provided parent (dataId), uses recursion to access needed parent */
            updateStructureChild(obj, parentDataId, payload, newBlockObject) {
                if( obj.children && !Array.isArray( obj.children ) ){
                    obj.children = Object.values( obj.children );
                }

                // block id matches needed parent id, update children array
                if( obj && obj.dataId === parentDataId ){
                    if( !obj.children ){
                        obj.children = [];
                    }
                    // appends new block at position and moves other blocks if needed
                    if ( !payload.absolute && payload.data[1] ) {
                        obj.children.splice( payload.data[1], 1 );
                        if ( parseInt( payload.data[1] ) < parseInt( payload.dropArea[1] ) ) {
                            obj.children.splice( parseInt( payload.dropArea[1] ) - 1, 0, newBlockObject );
                        } else {
                            obj.children.splice( payload.dropArea[1], 0, newBlockObject );
                        }
                    // appends new block 
                    } else if( !payload.absolute ) {
                        obj.children.splice( payload.dropArea[1], 0, newBlockObject );
                    // replaces entire children array if 'absolute' option is set
                    } else if( payload.absolute ){
                        obj.children = payload.data;
                    }
                // no id match and block has children array, loop through all children
                }else if( obj.children ){
                    obj.children.forEach( ( child ) => {
                        this.updateStructureChild( child, parentDataId, payload, newBlockObject );
                    } );
                }
            },
            removeStructureChild( obj, childrenDataId ) {
                if( obj.children && !Array.isArray( obj.children ) ){
                    obj.children = Object.values( obj.children );
                }
                let found = false;
                if( obj.children ){
                    obj.children.forEach( ( item, idx ) => {
                        if( found ) return;
                        if ( item.dataId === childrenDataId ) {
                            obj.children.splice( idx, 1 );
                            found = true;
                        }
                    } );
                }
                if( !found && obj.children ){
                    obj.children.forEach( function( child ) {
                        this.removeStructureChild( child, childrenDataId )
                    } );
                }
            }
        },
        mounted() {
            this.listBlocks().then((res) => {
                if (res.success) {
                    console.log(res);
                    this.blocksList = res.data.blocks;
                    this.categories = res.data.categories;

                    let allDataTypes = {};

                    Object.keys(this.blocksList).forEach(blockName => {
                        Object.keys(this.blocksList[blockName].fields).forEach(fieldName => {
                            if (
                                !allDataTypes[this.blocksList[blockName].fields[fieldName].datatype]
                            ) allDataTypes[this.blocksList[blockName].fields[fieldName].datatype] = blockName;
                        });
                    })

                    console.log('All possible data types for block fields:', allDataTypes);
                }

                if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }

                this.blocksLoaded = true;
            }).catch( err => console.log( err ) );
        },
        beforeRouteLeave (to, from, next) {
            next();
        },
    }
</script>

<style lang='scss' scoped>
    .space-blocks {
        display: flex;
        margin: 0 -25px;

        ::v-deep {
            .collapsible-content_header,
            .blocks-search .formulate-input input,
            .block-item__title,
            .collapsible-content,
            .collapsible-content * {
                font-family: "Oswald", sans-serif;
            }
        }

        .blocks {
            width: 320px;
            margin: 0 25px;

            ::v-deep {
                .block-item {
                    cursor: pointer;
                }

                .custom-button {
                    box-shadow: none !important;
                }

                .collapsible-content_header {
                    position: relative;
                    .header-title {
                        flex: 1 0 auto;
                        padding-right: calc(5px + 16px); /* 16px is max svg width */
                    }

                    > svg {
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        right: 5px;
                        bottom: 0;
                        margin: auto;
                        transition: all 300ms ease-out;
                    }

                    &:not(.collapsed) {
                        > svg {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        .editor {
            margin: 0 25px;
            width: calc(100% - 640px - 50px);

            .block {
                margin-top: 25px;

                > .add-area {
                    margin-top: 25px;
                }
            }

            .add-area {
                width: 100%;
                height: 100px;
                border: 1px dashed #b0afb5;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f6f6f6;

                .plus {
                    background-color: rgb(108, 65, 226);
                    font-size: 22px;
                    font-weight: 400;
                    height: 36px;
                    width: 36px;
                    padding: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    > svg {
                        fill: #fff;
                    }
                }
            }
        }

        .block-options {
            width: 320px;
            margin: 0 25px;
        }
    }
</style>